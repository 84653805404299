import './App.css';

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore/lite';
import { useEffect, useState } from 'react';


function App() {

  const [ users, setUsers ] = useState([]);

  const firebaseConfig = {
    apiKey: "AIzaSyBwSAJte_C4pohXmScS-S79lVVOLqMJwiI",
    authDomain: "hamro-vasa.firebaseapp.com",
    projectId: "hamro-vasa",
    storageBucket: "hamro-vasa.appspot.com",
    messagingSenderId: "839453491739",
    appId: "1:839453491739:web:7d1619b5aa7690d4cc6ebe",
    measurementId: "G-9FSRDZPY7Z"
  };

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const getUsers = async() => {
    
    const usersCol = collection(db, 'users');
    const usersSnapshot = await getDocs(usersCol);
    let userList = [];
    usersSnapshot.forEach((doc) => {
      const item = doc.data();
      userList.push(item);
    });

    setUsers(userList);
  }

  const addUser = async () => {
    try {
      const docRef = await addDoc(collection(db, "users"), {
        first: "Nirmal",
        last: "Limbu",
        born: 1987
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }    
  }
  
  useEffect(() => {
    getUsers();
    // addUser();
  }, []);

  useEffect(() => {
    if (users.length) {
      console.log(users)
    }
  }, [ users ]);

  return (
    <div className="App">
      <header className="App-header">
        <a
          className="App-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h1>Hamro Vasa <span> - launching soon...</span></h1>
        </a>

        {/* <UserComponent users={users} /> */}

      </header>
    </div>
  );
}

export default App;



const UserComponent = ({ users }) => {

  const list = users.map((item, key) => {
    return (
      <div key={key}>
        {item.first} {item.last} - {item.born}
      </div>
    )
  })

  return (
    <div>
      {list}
    </div>
  )

}